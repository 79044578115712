/* keyframes 정의 */
@keyframes slideUp {
    0% {
      transform: translateY(+200px);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  /* 애니메이션 적용할 엘리먼트에 스타일 추가 */
  .c2-element01 {
    animation: slideUp 2s ease-out;
    animation-delay: 1s;
    animation-fill-mode:forwards;
  }
  
  /* 추가적인 스타일링 (예: 배경색, 크기 등) */
  .c2-element01 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    color: #000;
    font-size: 22px;
    margin: 8px;
    width: 600px;
    height: 60px;
    text-align: center;
    border-radius: 30px;
  }

  .c2-element02 {
    animation: slideUp 1s ease-out;
    animation-delay: 1s;
    animation-fill-mode:forwards;
  }
  
  /* 추가적인 스타일링 (예: 배경색, 크기 등) */
  .c2-element02 {
    display: flex;
    background-color: #cccccc;
    width: 10px;
    height: 10px;
    text-align: center;
    border-radius: 5px;
    margin:10px;
  }

  .c2-element03 {
    animation: slideUp 1s ease-out;
    animation-delay: 3s;
    animation-fill-mode:forwards;
    /* transform: translateY(+100px); */
  }
  
  /* 추가적인 스타일링 (예: 배경색, 크기 등) */
  .c2-element03 {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 2rem;
  }