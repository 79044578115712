@keyframes c4-fadeIn-01 {
  from {
    opacity: 0;
    /* margin-left: 100%; */
  }
  to {
    opacity: 1;
    /* margin-left: 5%; */
  }
}

@keyframes c4-fadeIn-02 {
  from {
    opacity: 0;
    /* margin-left: 100%; */
  }
  to {
    opacity: 1;
    /* margin-left: -15%; */
  }
}
  



.c4-element04 {
    opacity: 1;
    margin-top: 60px;
    margin-left: 100px;
    margin-right: 100px;
    /* height: 500px; */
    background-color: white;
    border-radius: 90px;
    /* background-image: url("/public/images/content03_1.png");
    background-size: cover; */
    box-shadow: 2px 2px 5px 2px #bbb;
  }

.c4-backcolor {
    background: linear-gradient(to bottom, rgb(33 117 58) 80%, #fff 80%);
    
}

.c4-img01 {
  opacity: 0; 
  /* margin-left: 100%; */
  animation: c4-fadeIn-01 2s ease-in-out;
  animation-fill-mode:forwards;
}

.c4-img01:hover {
  z-index:99;
}

.c4-img01 {

  margin-left: 5%;
  height: 0;
  width: 33%;
  padding-top: calc(190 / 412 * 100%);
    
  /* background-color: yellow; */
  background-image: url("/public/images/content04_1.png");
  background-size: contain;
  background-position-y:bottom;
  background-repeat:no-repeat;
  z-index: 2;
}

.c4-img02 {
  opacity: 0; 
  /* margin-left: 100%; */
  animation: c4-fadeIn-02 2s ease-in-out;
  animation-fill-mode:forwards;
  animation-delay: 1.5s;
}
.c4-img02 {
  margin-left: -15%;
  height: 0;
  width: 33%;
  padding-top: calc(190 / 412 * 100%);
  
  /* background-color: pink; */
  background-image: url("/public/images/content04_2.png");
  background-size: contain;
  background-position-y:bottom;
  background-repeat:no-repeat;
  z-index: 3;
  scale: 0.9;
}
.c4-img02:hover {
  z-index:99;
}

.c4-img03 {
  /* margin-left: 100%; */
  opacity: 0; 
  animation: c4-fadeIn-02 2s ease-in-out;
  animation-fill-mode:forwards;
  animation-delay: 3.5s;
}
.c4-img03 {
  margin-top: 20px;
  margin-left: -15%;
  height: 0;
  width: 33%;
  padding-top: calc(190 / 412 * 100%);
    /* 472 622 */
  /* background-color: yellow; */
  background-image: url("/public/images/content04_3.png");
  background-size: contain;
  background-position-y:bottom;
  background-repeat:no-repeat;
  z-index: 4;
  scale: 0.9;
}
.c4-img03:hover {
  z-index:99;
}