@keyframes c6-disapper-animation {
    from {
      opacity: 1;
      /* margin-left: 100%; */
    }
    to {
      opacity: 0;
      /* margin-left: 5%; */
    }
  }

  
.c6-layout {
    background-color: #f4f4f4;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c6-title {
    margin-top: 8rem;
    font-size: 2.6rem;
    font-weight: bold;

}

.c6-title > span {
    color: rgb(33 117 58) ;
}

.c6-subtitle {
    margin-top: 0.8rem;
    font-size: 2.2rem;   
}

.c6-fromrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: pink; */
    margin-top: 2rem;
}

.c6-formlabel {
    font-size:1.6rem;
    font-weight: bold;
    padding-right: 10rem;
    width:10rem;
}

.c6-textfield{
    width:30rem;
}

.c6-checkrow {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    margin-top: 1.6rem;
}

.c6-check {
    width : 50rem;
}

.c6-infobox {
    width : 50rem;
    margin: .5rem;
    padding: 1.2rem;
    background-color: #fff;
    font-size: .9rem;
    border-style: solid;
    border-width: 1px;
    border-color: #bbb;
    border-radius: 16px;
}

.c6-error-msg {
    font-size: 2rem;
    color:red;
    /* opacity: 1;  */
 
    animation: c6-disapper-animation  1s ease-in-out ;
    /* animation-fill-mode:forwards; */
}
