/* keyframes 정의 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  

@keyframes slideUp {
  from {
    top: 100%;
    
  }
  to {
    top: 10%;
  }
}


@keyframes slideDown {
  from {
    top: -100%;
  }
  to {
    top: -50%;
  }
}


  /* 애니메이션 적용할 엘리먼트에 스타일 추가 */
  .c3-element01 {
    opacity: 0; 
    animation: fadeIn 4s ease-in-out;
  }
  
  /* 추가적인 스타일링 (예: 배경색, 크기 등) */
  .c3-element01 {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    color: #fff;
    font-size: 40px;
    opacity:1;
    font-weight: bold;
    margin: 8px;
    /* width: 600px;
    height: 60px; */
    text-align: center;
    margin-top: 200px;
  }

  .c3-element02 {
    opacity: 0; 
    animation: fadeIn 4s ease-in-out;
  }
  
  .c3-element02 {
    opacity: 1;
    margin-top: 60px;
    margin-left: 100px;
    margin-right: 100px;
    height: 500px;
    background-color: white;
    border-radius: 90px;
    background-image: url("/public/images/content03_1.png");
    background-size: cover;
  }

  .c3-step {
    color: #999;
    font-size: 16px;
    font-weight: bold;
    margin-top: 60px;
    margin-left: 60px;
  }

  .c3-title {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    margin-top: 0px;
    margin-left: 60px;
  }

  .c3-content01 {
    color: #000;
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
    margin-left: 60px;
  }

  .c3-content02 {
    color: rgb(33 117 58);
    font-size: 18px;
    font-weight: bold;
    margin-top: 0px;
    margin-left: 60px;
  }

  .c3-element03 {
    opacity: 0; 
    animation: fadeIn 4s ease-in-out;
  }
  
  .c3-element03 {
    opacity: 1;
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    /* height: 1200px; */
    background-color: white;
    border-radius: 30px;
  }

  .c3-mid-box {
    position: relative;
    /* height: 500px; */
    background-color: #f6f6f6;
    margin-left: 60px;
    margin-right: 60px;
    border-radius:30px;
    margin-top:30px;
  }
  
  .c3-mid-box-stack{
    position: relative;
    background: rgb(33 117 58);
    top: 30px;
    width:10px;
    height: 30px;
    margin-left: 30px;
  }

  .c3-mid-box-title {
    position: relative;
    top: 2px;
    font-size: 22px;
    font-weight: bold;
    height: 30px;
    margin-left: 50px;
  }

  .c3-mid-box_1 {
    display: flex;
    flex-direction: row;
    /* align-items:center; */
    align-items:end;
    justify-content: space-around;
    margin-top: 30px;
    /* height:400px; */
    /* background: #013322; */
    
  }

  .c3-mid-box_1_01 {
    opacity: 1; 
    animation: fadeIn 2s ease-in-out;
    animation-fill-mode:forwards;
    
  }

  .c3-mid-box_1_01 {
    
    margin-left: 20px;
    height: 0;
    width:30%;
    padding-top: calc(400 / 1000 * 100%);
    /* padding-top: calc(300 / 400 * 100%); */
    background-image: url("/public/images/content03_2_01.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    box-shadow: 0px 0px 5px 2px #aaa;
  }

  .c3-mid-box_1_02 {
    opacity: 0; 
    animation: fadeIn 2s ease-in-out;
    animation-delay: 1.5s;
    animation-fill-mode:forwards;
  }
  .c3-mid-box_1_02 {
    /* opacity: 1;  */
    height: 0;
    width:30%;
    /* padding-top: calc(248 / 351 * 100%); */
    padding-top: calc(400 / 1000 * 100%);
    /* padding-top: calc(300 / 400 * 100%); */
    background-image: url("/public/images/content03_2_02.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    box-shadow: 0px 0px 5px 2px #aaa;
  }

  .c3-mid-box_1_03 {
    opacity: 0; 
    animation: fadeIn 2s ease-in-out;
    animation-delay: 3.0s;
    animation-fill-mode:forwards;
  }

  .c3-mid-box_1_03 {
    margin-right: 20px;
    height: 0;
    width: 30%;
    /* padding-top: calc(248 / 351 * 100%); */
    padding-top: calc(400 / 1000 * 100%);
    /* padding-top: calc(300 / 400 * 100%); */
    background-image: url("/public/images/content03_2_03.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    box-shadow: 0px 0px 5px 2px #aaa;
  }
    


  .c3-mid-box_2 {
    
    position:relative;
    /* height: 500px; */
    background-color: #f6f6f6;
    margin-left: 60px;
    margin-right: 60px;
    border-radius:30px;
    margin-top:30px;
  }

  .img03-3-01 {
    /* background-color: #013322; */
    
    height: 0;
    width: calc(100% -100px);
    /* 1331 590 */
    padding-top: calc(590 / 1331 * 100% - 50px);
    
    background-image: url("/public/images/content03_3_01.png");
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    
  }

  .c3-mid-box-stack-02{
    position: absolute;
    background: rgb(33 117 58);
    top: 30px;
    width:10px;
    height: 30px;
    margin-left: 30px;
  }

  .c3-mid-box-title-02 {
    position: absolute;
    top: 32px;
    font-size: 22px;
    font-weight: bold;
    height: 30px;
    margin-left: 50px;
  }

  .c3-mobile01 {
    
    animation: slideUp 2s ease-in-out;
    animation-fill-mode: forwards;
  }


.c3-mobile01 {
  position: absolute;
  left:48%;
  top: 10%;
  
  height: 0;
  width: 50%;

  padding-top: calc(890 / 1331 * 100% - 50px);
  
  background-image: url("/public/images/content03_3_mobile_01.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;  
}

.c3-mobile02 {
    
  animation: slideDown 2s ease-in-out;
  animation-fill-mode: forwards;
}

.c3-mobile02 {
  position: absolute;
  left:70%;
  top: -50%;
  
  height: 0;
  width: 50%;

  padding-top: calc(890 / 1331 * 100% - 50px);
  
  background-image: url("/public/images/content03_3_mobile_02.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.img03-3-02 {
  /* background-color: #013322; */
  
  height: 0;
  width: calc(100% -100px);
  /* 1331 590 */
  padding-top: calc(590 / 1331 * 100% - 50px);
  
  background-image: url("/public/images/content3_4_02.png");
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  
  margin-bottom: 50px;
}