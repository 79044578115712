.c5-layout-01 {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    
    height:500px;
    margin-bottom: 8rem;
    /* background-color: pink; */
}

.c5-thumb {
    width: 340px;
    /*  554 681 */
    padding-top: calc(190 / 412 * 100%);
    background-image: url("/public/images/6-review-thumbup.png");
    background-size: 277px 340px;
    /* background-size: cover; */
    background-repeat:no-repeat;
    /* z-index: 1; */
}

.c5-content {
    display: flex;
    flex-direction: column;

    
    width:100%;
    
}

.c5-title {
    font-size: 36px;
    font-weight: bolder;
    color: rgb(33 117 58) ;
    margin-left: -110px;
}

.c5-title span {
    color: orange;
}




/* section */
.section {
    width: 100%;
    margin: 2rem auto;
    /* background-color: pink; */
    /* max-width: var(--max-width); */
  }
  
  /* @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  } */
  /*
  =============== 
  Slider
  ===============
  */
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  .title span {
    font-size: 0.85em;
    color: var(--clr-primary-5);
    margin-right: 1rem;
    font-weight: 700;
  }
  .section-center {
    /* margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    
    height: 450px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden; */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width:100%;
    
    /* background-color: blue;     */
  }

  article {
    transition: all 2s;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    /* background-image: url("/public/images/6-후기-cover.png");
    background-size: cover;
    background-repeat: no-repeat; */
    /* background-color: pink; */
  }

  .article-box {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #eee;
    border-style: solid;
    border-width: 1px;
    border-color: #bbb;
    border-radius: 20px;
  }

  .person-img {    
    border-radius: 50%;
    /* margin-bottom: 1rem; */
    
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 4px solid var(--clr-grey-8);
    box-shadow: var(--dark-shadow);
  }
  article h4 {
    text-transform: uppercase;
    color: var(--clr-primary-5);
    margin-bottom: 0rem;
  }
  .c5-article-title {
    margin-top: 0rem;
    text-transform: capitalize;
    margin-bottom: 2.0rem;
    color: var(--clr-grey-3);
  }
  .c5-article-text {
    max-width: 35em;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: normal;
    
    /* line-height: 1; */
    height: 8rem;
    overflow: hidden;
    /* color: var(--clr-grey-5); */
  }
  .c5-article-text > span {
    font-weight: bold;
    background: linear-gradient(to bottom, #eee 30%, #f4cd06 80%);
  } 
  /* .c5-article-text > span:before {
    content: <br />;
  } */

  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: var(--clr-primary-5);
  }
  
  @media (min-width: 800px) {
    .c5-article-text {
      max-width: 45em;
    }
  
  }
  
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(100%);
  }
  article.nextSlide {
    transform: translateX(-5%);
  }
  
  article.p01 {
    
    transform: translateX(100%);
    
  }

  article.p02 {
    transform: translateX(200%);
    
  }

  article.p03 {
    transform: translateX(300%);
  }

  article.p04 {
    transform: translateX( 0%);
  }

  article.p05 {
    transform: translateX( -100%);
  }

  article.p06 {
    transform: translateX( -200%);
  }

  article.p07 {
    transform: translateX( -300%);
  }