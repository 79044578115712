.footer-layout {
    width:100%;
    display: flex;
    flex-direction: column;
    margin-top: 1.0rem;   
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #404040;
    color:#fff;
}

.c6-text01 {
    margin-left: 7rem;
    font-size: 2.0rem;
    font-weight: bold;
}

.footer-bottom {
    display: flex;
    width:100%;
    flex-direction: row;
    margin-top: 8rem;
    align-items: center;
    /* background-color: #555; */
}

.c6-text02 {
    margin-left: 7rem;
    margin-top: 0.6rem;
    font-size: 1.4rem;
    font-weight: normal;
}

.c6-logolayout {
    display: flex;
    flex-direction: row;
    width:30%;
    
    align-items: center;
    justify-content: flex-end;
}

.c6-logo {
    width: 30rem;
    height: 3rem;
    background-image: url("/public/images/17.png"); 
    background-size: contain;
    background-position-x:right;
    background-repeat:no-repeat; 
    margin-right: 3rem;
     
    /* background-color: green; */
}